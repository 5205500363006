@import '~bootstrap/scss/bootstrap';

$navBar: #1E2632;
$background: #f7f8f9;
$text: #3A3C42;

body {
    background-color: $background;
    color: $text;
}

.navbar {
    background-color: #FFFFFF;
    box-shadow: 0 5px 10px 0 rgba(204, 204, 204, 0.3);
    a{
      color: $navBar;
    }
}

.force-black {
    *{
        color: #000000 !important;
    }
}

.login-container, .register-container {
    width: 500px;
    margin: 0 auto;
}

.upload-content {
    position: relative;
    background-color: #f1eff3;
    background-size: cover;
    padding: 10px 0;
}

.candidates-content {
    margin: 20px 5px;
    margin-top: 0;

}

#candidates-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    //width: 100%;
    width: max-content;
    overflow: scroll;
    border-top: none;
    min-width: 1200px;
    *{
        font-size: 13px;
    }
}

#candidates-table td, #candidates-table th {
    border: 1px solid #ddd;
    padding: 3px;
    max-width: 225px;
    font-weight: normal;
}

#candidates-table tr:nth-child(even){background-color: #f2f2f2;}

#candidates-table tr:hover {background-color: #ddd;}

#candidates-table th {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    background-color: #007db7;
    color: white;
    .form-control {
        padding: 2px !important;
        height: 37px;
    }
}

.filters__pagination {
    border-top: none !important;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    position: absolute;
    left: 50%;
    right: 50%;
    padding-top: 20px;
    padding-bottom: 20px;

    li {
        margin-left: 5px;
        margin-right: 5px;

        &.active {
            background-color: $navBar;
            color: white;
        }

        &.previous, &.next {
            a {
                color: black;
                font-weight: 700;
            }
        }

        &.disabled {
            a {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }

    li:hover:not(.active) {background-color: #ddd;}
}

.nothing-found {
    font-size: 20px;
    width: 300px;
    margin: 0 auto;
    text-align: center;
}

.action-buttons {
    display: flex;
    flex-direction: column;
    align-items: end;
    font-size: 13px;

    .edit-button {
        color: gray;
        cursor: pointer;
    }

    .delete-button {
        color: red;
        cursor: pointer;
    }
}

.close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    right: 32px;
    top: 32px;
    opacity: 1;
    background: red;
    width: 50px;
    height: 50px;
    border: 0;

    &:hover {
        opacity: 1;
    }
}

.export-button {
    margin-left: 50px;
    color: green;
    border: 1px solid green;
}

.modal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;


    .delete-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 400px;
        align-items: center;
        margin: 0 auto;
        margin-top: 50px;
    }

    .modal-title {
        font-size: 30px;
        text-align: center;
        font-weight: bold;
    }

    .candidate-info {
        font-size: 25px;
        font-weight: 700;
        margin-top: 30px;
    }

    .buttons-container {
        margin-top: 90px;
        display: flex;
        justify-content: space-around;
        width: 65%;
    }
    .edit-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 800px;
        align-items: center;
        margin: 0 auto;

        .candidate-form {
            width: 100%;
            form {
                text-align: center;
                margin-top: 15px;
                label {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 25px;
                    font-weight: bold;
                    text-align: left;
                }
            }
        }

        .buttons-container {
            margin-top: 40px;
            margin-bottom: 40px;
            width: 100%;
            display: flex;
            justify-content: center;

            button {
                margin-left: 30px;
            }
        }
    }
}

.admins-container {
    margin: 30px;

    .admin-row {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        font-size: 18px;
    }

    .admin-label {
        margin-left: 10px;
    }

    img {
        border-radius: 50%;
        width: 45px;
        margin-left: 10px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        margin-left: 15px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

.th-table-sort {
    display: flex;
    cursor: pointer;

    span {
        width: max-content;
    }
}
